import React from 'react';
import { View, Text, StyleSheet } from 'react-native';
import { styles } from '../../main.styles';

import PageView from '../../components/views/page.view.component'
import Button from '../../components/button.component';
import OutlineButton from '../../components/outlineButton.component';
import LinkButton from '../../components/linkButton.component';

import { ModelComponent, connect } from '../ModelComponent';

import { Playlist } from './playlist.component';
import IconButton from '../../components/iconButton.component';
import AddPlaylistModal from './modals/addPlaylist.modal'

class SignageScreenView extends ModelComponent {
  constructor(props) {
    super(props);
    this.state = {
      ...this.state,
      addPlaylistModalOpen: false
    }
  }
  renderContent() {
    return (
      <PageView>
		<AddPlaylistModal
          open={this.state.addPlaylistModalOpen} 
          closeModal={() => this.setState({addPlaylistModalOpen: false})} 
        />
        {this.makeList('SignagePlaylist', (data) => (
          <Playlist id={data.id.value} key={data.id.value} />
        ))}
        <View style={{flexDirection: 'row', justifyContent: 'center', marginTop: 10}}>
          <LinkButton title="Add Playlist" onPress={() => this.setState({addPlaylistModalOpen: true})} />
        </View>
      </PageView>
    );
  }
}
export default SignageScreenView = connect({
	component: SignageScreenView,
	models: [{
  		model: 'SignagePlaylist', 
  		list: true,
	}]
});


/*
import React from 'react';
import { View, Text, TextInput } from 'react-native';
import { connect } from 'react-redux';
import * as Constants from '../../constants';
import { styles } from '../../main.styles';

import socket from '../../core/socket.api';

import PageView from '../../components/views/page.view.component'
import Device from '../../components/device.component';
import Button from '../../components/button.component';
import Loader from '../../components/loader.component';

class MyDevices extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      deviceIDToClaim: null,
    };
  }
  claimDevice = (deviceID) => {
    if (!deviceID) return;
    socket.emit('claimDevice', {"id": deviceID, "status": false, "connectionType": Constants.CONNECTION_TYPE_WIFI});
  }
  render() {
    const devicesList = this.props.devices.devices;

    return (
      <PageView>
        <Text style={styles.title}>ACB Devices</Text>

        <View style={{marginBottom: 100}}>
          {this.props.devices.loaded
            ? <>
                {Object.keys(devicesList).length == 0 && <View style={styles.centerView}>
                  <Text style={[styles.centerText, {marginBottom: 20}]}>You haven't added any devices to your account...</Text>
                  <Text style={[styles.centerText, styles.greyText]}>Connect your device to a WiFi network, and enter it's device ID below.{"\n"}{"\n"}Once the device has connected, click "Claim This Device" to add it to your account.</Text>
                </View>}

                {Object.keys(devicesList).map((key, index) => { 
                  const device = devicesList[key];
                  return (
                    <Device key={device.id} userType={this.props.userType} id={device.id} name={device.deviceName} status='Online' slots={device.status?.slots} wifiConnected={device.wifiConnected} usbConnected={false} bleConnected={device.bleConnected} />
                  )
                })}
              </>
            : <Loader size='large' text="Loading Devices..." />
          }
        </View>
        {this.props.userType != 'simple' && <>
          <View style={{marginBottom:10}}>
            <Text>Enter your device ID to add it to your devices:</Text>
            <Text style={{color:'grey'}}>You can find your device ID after connecting the device to your wifi network. Once the device has connected to the internet, you will find the device ID in: Settings -&gt; Network Status</Text>
          </View>
          
          <View style={styles.inlineView}>
            <TextInput
              style={[styles.input, {marginBottom: 0}]}
              placeholder="Device ID"
              onChangeText={deviceIDToClaim => this.setState({deviceIDToClaim: deviceIDToClaim})}
              underlineColorAndroid="transparent"
              placeholderTextColor="grey"
              autoCapitalize="characters"
            />
            <View style={{marginLeft: 10}}>
              <Button
                onPress={() => this.claimDevice(this.state.deviceIDToClaim)}
                title="Claim"
              />
            </View>
          </View>
          {this.props.devices.claimDeviceError && <Text style={styles.error}>{this.props.devices.claimDeviceError}</Text>}
        </>}
      </PageView>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    devices: state.devices.devices,
    userType: state.userData.userData.userData.userType,
  }
};
export default connect(mapStateToProps)(MyDevices);
*/