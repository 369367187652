import React from 'react';
import { View, Text, StyleSheet } from 'react-native';
import { styles } from '../../main.styles';

import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome'
import { faTimesCircle, faCheckCircle, faWifi } from '@fortawesome/free-solid-svg-icons'
import { faUsb, faBluetooth } from '@fortawesome/free-brands-svg-icons'

import { ModelComponent, connect } from '../ModelComponent';

import PageView from '../../components/views/page.view.component'
import Button from '../../components/button.component';
import OutlineButton from '../../components/outlineButton.component';
import IconButton from '../../components/iconButton.component';

import PlaylistSettingsModal from './modals/playlistSettings.modal';
import LinkButton from '../../components/linkButton.component';

class PlaylistComponent extends ModelComponent {
	constructor(props) {
		super(props);
		this.state = {
			settingsOpen: false,
		}
	}
	renderContent() {
		const getValue = this.modelValueGetter('SignagePlaylist', this.props.id);
		const boxStyle = (this.props.showBox === false ? { flexDirection: 'row', gap: 10, padding: 10, flexWrap: 'wrap' } : [styles.shadowBoxNoPadding, { flexDirection: 'row', gap: 10, padding: 10, flexWrap: 'wrap' }]);
		return (<>
			{this.accountAccess === 'admin' && <PlaylistSettingsModal
				id={this.props.id}
				open={this.state.settingsOpen}
				closeModal={() => this.setState({ settingsOpen: false })}
			/>}
			<View style={boxStyle}>
				<View style={{ flex: 1, flexDirection: 'column', minWidth: 270 }}>
					<Text style={[styles.title, { marginBottom: 5 }]}>{getValue('name')}</Text>
					{this.accountAccess === 'admin' && <LinkButton style={{ marginBottom: 10 }} color={'#8a745a'} stretchContainer={false} title="Open Playlist Settings" onPress={() => this.setState({ settingsOpen: true })} />}
					<Text>{getValue('description')}</Text>
				</View>
				{this.renderError()}
			</View>
		</>);
	}
}
export const Playlist = connect({
	component: PlaylistComponent,
	models: [{
		model: 'SignagePlaylist',
	}]
});
