import React from 'react';
import { View, Text } from 'react-native';
import { styles } from '../../../main.styles';

import { faSortUp, faSortDown } from '@fortawesome/free-solid-svg-icons'

import Button from '../../../components/button.component';
import OutlineButton from '../../../components/outlineButton.component';
import LinkButton from '../../../components/linkButton.component';
import IconButton from '../../../components/iconButton.component';

import { ModelModal, connect } from '../../ModelComponent';

import AddPlaylistItemModal from './addPlaylistItem.modal';
import PlaylistItemSettingsModal from './playlistItemSettings.modal';

class PlaylistSettingsModal extends ModelModal {
	constructor(props) {
		super(props, "Playlist Settings");
	}
	renderModal() {
		const form = this.getFormHelper('SignagePlaylist', this.props.id);
		let nextIndex = 0;
		const itemList = {};
		this.getModelList('SignagePlaylistItem', (modelData) => {
			const data = modelData.model.data;
			itemList[data.playlistIndex.value] = data;
			if (!this.props.id == data.playlistID.value) return;
			if (data.playlistIndex.value >= nextIndex) nextIndex = data.playlistIndex.value + 1;
		});
		const playlistItemSorter = (a, b) => {
			return a.model.data.playlistIndex.value - b.model.data.playlistIndex.value;
		}
		return (<>
			<AddPlaylistItemModal
				open={this.state.addPlaylistItemModalOpen}
				playlistID={this.props.id}
				playlistIndex={nextIndex}
				closeModal={() => this.setState({ addPlaylistItemModalOpen: false })}
			/>
			{form.textInput('name')}
			{form.textInput('description', true)}
			{form.colorPicker('backgroundColor', true)}
			<Text style={[styles.label, { marginVertical: 10 }]}>Playlist Items:</Text>
			{this.makeSortedList('SignagePlaylistItem', playlistItemSorter, (data) => (
				<View key={data.id.value}>
					{this.state.playlistItemSettingsOpen?.[data.id.value] && <PlaylistItemSettingsModal
						id={data.id.value}
						open={this.state.playlistItemSettingsOpen?.[data.id.value]}
						closeModal={() => this.updateState({ playlistItemSettingsOpen: { ...this.state.playlistItemSettingsOpen, [data.id.value]: false } })}
					/>}
					<View style={{ flexDirection: 'row', gap: 5, alignItems: 'center', borderBottomWidth: 1, borderBottomColor: "#8a745a" }}>
						<View style={{ flexDirection: 'column' }}>
							<IconButton size={20} margin={0} style={{ marginBottom: -8 }} icon={faSortUp} onPress={() => {
								if (data.playlistIndex.value <= 0) return;
								const form = this.getFormHelper('SignagePlaylistItem', data.id.value);
								form.setModelField('playlistIndex', data.playlistIndex.value - 1).then(() => {
									if (data.playlistIndex.value - 1 in itemList) {
										const formForSwap = this.getFormHelper('SignagePlaylistItem', itemList[data.playlistIndex.value - 1].id.value);
										formForSwap.setModelField('playlistIndex', data.playlistIndex.value).then(() => {
											form.save();
											formForSwap.save()
										});
									} else {
										return form.save();
									}
								});
							}} />
							<Text style={{ fontWeight: 500, width: 30, textAlign: 'center' }}>{data.playlistIndex.value + 1}</Text>
							<IconButton size={20} margin={0} style={{ marginTop: -8 }} icon={faSortDown} onPress={() => {
								if (data.playlistIndex.value + 1 >= nextIndex) return;
								const form = this.getFormHelper('SignagePlaylistItem', data.id.value);
								form.setModelField('playlistIndex', data.playlistIndex.value + 1).then(() => {
									if (data.playlistIndex.value + 1 in itemList) {
										const formForSwap = this.getFormHelper('SignagePlaylistItem', itemList[data.playlistIndex.value + 1].id.value);
										formForSwap.setModelField('playlistIndex', data.playlistIndex.value).then(() => {
											form.save();
											formForSwap.save()
										});
									} else {
										return form.save();
									}
								});
							}} />
						</View>

						<Text style={{ flex: 1, fontWeight: 500 }}>{data.name.value}</Text>

						<IconButton size={20} onPress={() => this.updateState({ playlistItemSettingsOpen: { ...this.state.playlistItemSettingsOpen, [data.id.value]: true } })} />
					</View>
				</View>
			), null, 10)}
			<View style={{ flexDirection: 'row', justifyContent: 'center', marginTop: 10 }}>
				<LinkButton title="Add Item" onPress={() => this.setState({ addPlaylistItemModalOpen: true })} />
			</View>
		</>);
	}
	renderModalBottom() {
		const form = this.getFormHelper('SignagePlaylist', this.props.id);
		return (<>
			<View style={{ flexDirection: 'row' }}>
				<View style={{ flex: 1, justifyContent: "center" }}>
					<LinkButton title="Close" color="#A00" style={{ width: 200 }} onPress={() => this.props.closeModal()} />
				</View>
				<View style={{ flex: 1, flexDirection: 'row-reverse' }}>
					<Button title="Done" style={{ width: 100 }} onPress={() => form.save().then(() => this.props.closeModal()).catch(console.error)} />
				</View>
			</View>
		</>);
	}
}
export default PlaylistSettingsModal = connect({
	component: PlaylistSettingsModal,
	models: [{
		model: 'SignagePlaylist',
		loadChildren: true,
	}, {
		model: 'SignagePlaylistItem',
		list: true,
		keyName: 'playlistID',
		key: (r, props) => props.id,
	}]
});

