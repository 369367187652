const mustUseLiveServer = true;//false;//true;//false; //true;

const host = window.location?.host;
const localhostText = host?.split(':')[0];
const subdomain = localhostText?.split('.')[0]; //'staging'; //
const isLocal = (subdomain === '192' || subdomain === '127' || localhostText === 'localhost');
const subdomainText = (subdomain === 'staging') ? subdomain+'.' : '';

console.log(subdomain, localhostText)

export const DOMAIN = isLocal ? 'http://'+localhostText+':19006/' : 'https://'+subdomainText+'cxreveal.com';
export const SERVER_DOMAIN = (isLocal && !mustUseLiveServer) ? 'http://'+localhostText+':50445' : 'https://'+subdomainText+'server.cxreveal.com';
export const APP_NAME = 'CX Reveal';
export const CONNECTION_TYPE_NONE = 0;
export const CONNECTION_TYPE_WIFI = 1;
export const CONNECTION_TYPE_BLE = 2;
export const CONNECTION_TYPE_USB = 3;