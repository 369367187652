import React from 'react';
import { View, Text, StyleSheet } from 'react-native';
import { styles } from '../../main.styles';

import Button from '../../components/button.component';
import OutlineButton from '../../components/outlineButton.component';
import LinkButton from '../../components/linkButton.component';

import { ModelEditorComponent, connect } from '../ModelComponent';

class PageSettings extends ModelEditorComponent {
	constructor(props) {
		super(props);
		this.state = {
			...this.state,
			nameChanged: false,
		}
	}
	componentDidUpdate(prevProps, prevState) {
		super.componentDidUpdate(prevProps, prevState);
		if (prevProps.id !== this.props.id) {
			//this.loadModels(true);
			this.resetModel();
		}
	}
	renderContent() {
		const editingModal = this.props.editingModal;
		const form = this.getFormHelper('TemplatePage', this.props.id);
		const save = ()=> form.save().then(() => { 
			if (this.props.onChange) this.props.onChange(); 
			this.updateState({nameChanged: false});
		}).catch(console.error);
		return (<>
			{form.textInput({name: 'name', onChange: ()=> this.updateState({nameChanged: true})})}
			{this.state.nameChanged && <LinkButton title="Save Name" style={{flexDirection: 'row', justifyContent: 'center', marginBottom: 10}} onPress={()=> save()} />}

			{editingModal && <ModalSizeEditor id={form.value('templatePositionDataID')} />}

      		{/* this.renderModelTextInput('description', true) */}
			<Text style={styles.label}>Background:</Text>
			{form.colorPicker({name: 'backgroundColor', label: 'Colour', onChange: ()=> save(), palette: this.makeSimpleList('PaleteColor', data => data.color.value), onPaletteAdd: (color) => {
				this.resetDefaultModel('PaleteColor')
				.then(() => this.setModelFields({
					name: color,
					color
				}, 'PaleteColor', 'default'))
				.then(() => {
					this.saveModel(true, null, 'PaleteColor', 'default').catch(console.error);
				});
			}})}
			{form.filePicker({label: 'Image', name: 'backgroundImage', onChange: ()=> save()})}

			<LinkButton title="Delete Page?" color="#A00" style={{marginTop: 10}} onPress={() => {
				const deletePage = (pageID) => {
					const pageComponentChildren = [];
					const addComponentToPageChildren = (data) => {
						if (data.type.value == 'modal') {
							console.log(data.children);
							const children = [];

							//data.children[childName][child.id] = {model: child.model, data: childData};
							const addChildren = (data, list) => {
								console.log(data, list);
								if (data.children) for (const childName in data.children) {
									for (const childID in data.children[childName]) {
										const child = data.children[childName][childID];
										console.log(child);
										if ((!child) || (!child.model) || (!child.data?.id?.value) || child.model == 'ButtonStyle' || child.model == 'TextStyle') continue; //model not ButtonStyle or TextStyle
										
										const childItem = {
											model: child.model,
											id: child.data.id.value,
											children: [],
										};
										addChildren(child.data, childItem.children);

										console.log(childItem);
										list.push(childItem)
									}
								}
							}
							addChildren(data, children);

							console.log(children);

							pageComponentChildren.push({
								model: 'TemplatePage',
								id: data.id.value,
								children
							})
						} else pageComponentChildren.push({
							model: 'TemplateComponent', 
							id: data.id.value, 
							children: [
								{
									model: 'TemplatePositionData',
									id: data.templatePositionDataID?.value,
									children: []
								},
								{
									model: 'TemplateComponent'+this.ucFirst(data.type.value),
									id: data.componentID?.value,
									children: []
								},
							]
						});
					}

					const componentList = [
						...this.getModelList('TemplateComponent', (model) => model.model?.data.templatePageID?.value == pageID), 
						...this.getModelList('TemplatePage', (model) => model.model?.data.type?.value === 'modal' && model.model?.data.templatePageID?.value == pageID, true)
					];
					const components = {
						'button': [],
						'modal': [],
						'text': [],
						'image': [],
						'widgets': [],
					}
					//console.log(componentList, this.props, this.state);
					for (const component of componentList) {
						if (component.type.value in components) components[component.type.value].push(component);
						else components['widgets'].push(component);
					}

					for (const componentType in components) {
						components[componentType].forEach(component => {
							addComponentToPageChildren(component);
						});
					}

					return this.deleteModel({
						model: 'TemplatePage',
						id: pageID,
						children: pageComponentChildren
					});
				}
				this.showConfirmDialogue("Delete "+form.value('name')+"?", "Are you sure you want to delete this Page?")
				.then(() => { deletePage(this.props.id).then(() => this.props.onDelete()) })
				.catch(err => console.log(err));
			}} />
		</>);
	}
}

export default PageSettings = connect({
	component: PageSettings,
	models: [{
		model: 'TemplatePage',
		loadChildren: true,
		key: (root, props) => props.id,
	}, { model: 'PaleteColor', list: true }]
});


class ModalSizeEditorBase extends ModelEditorComponent {
	componentDidUpdate(prevProps, prevState) {
		super.componentDidUpdate(prevProps, prevState);
		if (prevProps.id !== this.props.id) {
			this.resetModel();
		}
	}
	render() {
		return (<>
			{this.renderModelTextInput({name: 'width', model: 'TemplatePositionData', id: this.props.id, onChange: ()=> this.saveModel(false, null, 'TemplatePositionData', this.props.id)})}
			{this.renderModelTextInput({name: 'height', model: 'TemplatePositionData', id: this.props.id, onChange: ()=> this.saveModel(false, null, 'TemplatePositionData', this.props.id)})}
		</>)
	}
}

const ModalSizeEditor = connect({
	component: ModalSizeEditorBase,
	models: [{
		model: 'TemplatePositionData',
	}]
});