import React from 'react';
import { View, Text, StyleSheet } from 'react-native';
import { styles } from '../../main.styles';

import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome'
import { faTimesCircle, faCheckCircle, faWifi } from '@fortawesome/free-solid-svg-icons'
import { faUsb, faBluetooth } from '@fortawesome/free-brands-svg-icons'

import { ModelEditorComponent, ModelComponent, connect } from '../ModelComponent';

import PageView from '../../components/views/page.view.component'
import Button from '../../components/button.component';
import OutlineButton from '../../components/outlineButton.component';

import TemplatePagePreview from '../../components/templatePagePreview.component';

class TemplatePreviewInnerClass extends ModelComponent {
	constructor(props) {
		super(props);
		this.state = {
			...this.state,
			previewSize: { width: 0, height: 0 },
		};
		if (this.props.refreshCall) this.props.refreshCall.fn = () => {
			//this.resetModel(() => this.loadModels(true));
			this.loadModels(true)//.then(()=> this.resetModel());
		}
	}
	componentDidUpdate(prevProps, prevState) {
		super.componentDidUpdate(prevProps, prevState);
		if (this.props.currentPageID && prevProps.currentPageID != this.props.currentPageID) {
			this.loadModels(true)//.then(()=> this.resetModel());
		}
	}
	renderContent() {
		const { id, currentPageID, onChange } = this.props;
		const getTemplateValue = this.modelValueGetter('KioskTemplate', id);
		const screenResolutionX = getTemplateValue('screenResolutionX');
		const screenResolutionY = getTemplateValue('screenResolutionY');
		const previewDataGroupID = getTemplateValue('previewDataGroupID');
		const previewDataSetID = getTemplateValue('previewDataSetID');

		const previewDataSetOverride = this.props.previewDataSetOverride;

		const modelComponents = this.getModelList('TemplateComponent', data => data.model.data.templatePageID.value == currentPageID).map(component => {
			if (this.props.hiddenComponents.includes(component.id.value)) return { type: 'hidden' };

			const newComponent = {};

			for (const fieldName in component) newComponent[fieldName] = component[fieldName].value;
			newComponent.positionData = this.getModelData('TemplatePositionData', newComponent.templatePositionDataID)?.data;
			const typedComponent = this.getModelData('TemplateComponent'+newComponent.type.substring(0,1).toUpperCase()+newComponent.type.substring(1), newComponent.componentID);
			newComponent.component = typedComponent?.data;
			if (newComponent.component) switch (newComponent.type) {
				case 'button':
					const buttonStyle = this.getModelData('ButtonStyle', typedComponent?.data?.buttonStyleID)?.data;
					newComponent.component.buttonStyle = buttonStyle;
					if (buttonStyle) {
						if (!buttonStyle.resizable && newComponent.positionData) {
							newComponent.positionData.width = buttonStyle.width;
							newComponent.positionData.height = buttonStyle.height;
						}
						if (buttonStyle?.textStyleID) buttonStyle.textStyle = this.getModelData('TextStyle', buttonStyle.textStyleID)?.data;
					}
				break;
				case 'text':
					newComponent.component.textStyle = this.getModelData('TextStyle', typedComponent?.data?.textStyleID)?.data;
					//console.log(id, previewDataGroupID, previewDataSetID)
					if (newComponent.component.isLinked) {
						if (previewDataGroupID && previewDataSetID) {
							const previewData = this.getModelValues(['customerName', 'customerMessage'], 'DataSet', previewDataSetID);
							newComponent.component.previewDataSet = previewData;
						}
						newComponent.component.previewDataSetOverride = previewDataSetOverride;
					}
				break;
			}
			return newComponent;
		});

		const components = {
			buttons: modelComponents.filter(component => component.type === 'button'),
			images: modelComponents.filter(component => component.type === 'image'),
			texts: modelComponents.filter(component => component.type === 'text'),
			countdowns: [], //modelComponents.filter(component => component.type === 'countdown'),
		}

		const previewWidth = this.state.previewSize.width;

		return (
			<View style={{ flex: 1, width: '100%', backgroundColor: '#AAA' }}>
				<View style={{flex: 1, alignItems: 'center', overflow: 'hidden'}} onLayout={(layoutEvent) => {
					const { width, height } = layoutEvent.nativeEvent.layout;
					//console.log(width, height);
					this.updateState({ previewSize: { width, height } });
				}}>
				{previewWidth > 0 && <TemplatePagePreview
					style={{ width: Math.min(screenResolutionX, previewWidth-(previewWidth*0.02)), maxHeight: screenResolutionY, marginVertical: 20, elevation: 10}}
					editable={false}
					editorWidth={screenResolutionX}
					editorHeight={screenResolutionY}
					grid={{showGrid:false, snapToGrid:false, gridSize:null}}
					/* style={{ borderRadius: page.fullScreen ? 0 : page.borderRadius }} */
					backgroundImageURI={this.getImageUri(this.getModelValue('backgroundImage', 'TemplatePage', currentPageID))}
					backgroundColor={this.getModelValue('backgroundColor', 'TemplatePage', currentPageID)}
					components={components}
					/> }
				</View>
				{this.renderError()}
			</View>
		);
	}
}
const TemplatePreviewInner = connect({
	component: TemplatePreviewInnerClass,
	loadingName: 'Preview',
	models: [
		{model: 'LibraryItem', list: true, keyField: 'siteID', useKey: true, key: (root) => root.userData.siteID},
		{ model: 'KioskTemplate', keyField: 'id', key: (root, props) => props.id, loadChildren: true },
		{ model: 'DataSet', key: (root, props) => props.previewDataSetID },
		//{ model: 'TemplatePage', keyField: 'id', key: (root, props) => props.currentPageID },
		{ model: 'TextStyle', list: true },
		{ model: 'ButtonStyle', list: true },
		//{ model: 'TemplateComponent', keyField: 'templatePageID', list: true, useKey: true, loadChildren: true, key: (root, props) => props.currentPageID },
	],
});

class TemplatePreview extends ModelComponent {
	render() {
		//console.log(this.getModelList('TemplatePages'), this.props.models.TemplatePages);
		let currentPageID = this.props.pageID;
		if (!currentPageID && this.props.models.TemplatePage) for (const pageID in this.props.models.TemplatePage) { currentPageID = pageID; break; }
		const getTemplateValue = this.modelValueGetter('KioskTemplate', this.props.id);
		const previewDataGroupID = getTemplateValue('previewDataGroupID');
		const previewDataSetID = getTemplateValue('previewDataSetID');
		return (
			<TemplatePreviewInner id={this.props.id} previewDataSetID={previewDataSetID} currentPageID={currentPageID} previewDataSetOverride={this.props.previewDataSetOverride} hiddenComponents={[]} />
		);
	}
}

export default TemplatePreview = connect({
	component: TemplatePreview,
	loadingName: 'Preview',
	models: [
		{ model: 'KioskTemplate', keyField: 'id', key: (root, props) => props.id, loadChildren: true }
	]
});